* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html,
body {
	min-height: 100vh;
	background-color: #ffffff;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
}

.main {
	width: 100%;
	max-width: 30rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	background: url('./assets/images/pix-bg.png') no-repeat;
	background-size: 100% 100%;
	mix-blend-mode: multiply;
}
@media screen and (max-width: 768px) {
	.main {
		min-height: 100vh;
	}
}

.title {
	margin-top: 1rem;
}
.returnImg {
	width: 2rem;
	height: 2rem;
	background: center no-repeat url('./assets/images/return2.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-left: 20px;
	margin-bottom: 0.3125rem;
}
.titleText {
	margin-bottom: 1.875rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 600;
	font-size: 1.1875rem;
	color: #000000;
	text-align: center;
	line-height: 1.375rem;
}
.main-content {
	width: 18.6875rem;
	height: 22.5rem;
	margin: 0 auto;
	background-image: url('./assets/images/code-bg.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 1.125rem;
	opacity: 0.95;
	box-shadow: 0px 1.8125rem 1.375rem -1.8125rem rgba(64, 93, 169, 0.2);
	position: relative;
	z-index: 1;
}
.main-content .codeBox {
	width: 11.75rem;
	height: 11.75rem;
	margin: 2.625rem auto 2.0625rem auto;
	text-align: center;
}
.main-content .amount {
	margin-bottom: 0.6875rem;
	font-family: Inter, Inter;
	font-weight: bold;
	font-size: 24px;
	color: #405da9;
	line-height: 28px;
	text-align: center;
}
.main-content .expireAt {
	font-family: Inter, Inter;
	font-weight: 400;
	font-size: 14px;
	color: #666666;
	line-height: 16px;
	text-align: center;
}

.footer {
	width: 100%;
	text-align: center;
	margin: 29px auto 37px auto;
}

.footer .button,
.modal button {
	border-color: transparent;
	width: 299px;
	height: 47px;
	background: #405da9;
	box-shadow: 0px 0px 17px 0px rgba(20, 26, 118, 0.11);
	border-radius: 36px;
	font-family: Inter, Inter;
	font-weight: 500;
	font-size: 16px;
	color: #fafafa;
	line-height: 19px;
	text-align: center;
}
.tips {
	width: 299px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.tips div {
	font-family: Inter, Inter;
	font-weight: 400;
	font-size: 15px;
	color: #444444;
	line-height: 17px;
	text-align: left;
	font-style: normal;
	text-transform: none;
	word-spacing: -1px;
	margin-bottom: 1.9375rem;
}

.Loading {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal .mask {
	height: 100%;
	width: 100%;
	background-color: #000000;
	position: fixed;
	left: 0;
	top: 0;
	opacity: 0.3;
	z-index: 1;
}

.modal .modal-container {
	z-index: 2;
	border-radius: 0.9375rem;
	background-color: #ffffff;
	padding: 1.875rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 1.25rem;
	font-size: 1rem;
	width: 299px;
}

.modal .modal-container .title {
	width: 100%;
	text-align: center;
	font-family: Inter;
	font-size: 1.2rem;
	font-weight: bold;
}

.modal .modal-container .content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
}
.modal .modal-container .content img {
	width: 60px;
}
.modal .footer {
	padding: 0;
	width: 80%;
}

.modal button {
	width: 100%;
}

.modal button:focus-visible {
	outline: 0;
}
